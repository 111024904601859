.dropdown-menu {
  border-radius: 0;
  font-size: rem($font-size-large);
  background: $blue_darker;
  border-color: $dropdown-bg;
  margin: 0;
  padding: 0;
  min-width: rem(245);
  color: white;
  & > li {
    @include transition(background-color 0.5s ease);
    width: 100%;
    position: relative;
    &:hover,
    &.active {
      background-color: $dropdown-link-hover-bg;
    }
    > a {
      color: #fff;
      position: relative;
      padding: $grid-gutter-width/2;

      &:hover,
      &:focus,
      &.active {
        background-color: transparent !important;
      }
    }
  }
}

.open > .dropdown-arrow {
  display: block;
}
.dropdown-arrow {
  position: absolute;
  display: none;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
  border-bottom-color: $blue_darker;
  z-index: 2000;
}

.dropdown-inverse {
  ul.dropdown-menu {
    background: $blue_darker;

    & > li > a {
      color: #fff;
      &:hover {
        //background-color: $blue-dark;
      }
    }
  }
}
