// Theme Customizations to Forms/Webforms //

.throbbing {
  .input-group-addon {
    .glyphicon-refresh {
      @include animated-throbber;
    }
  }
}

html.js {
  input.throbbing {
    background: none;
  }
}

form {
  .table-bordered{
    border: none;
  }
  .help-block {
    @include vbf-margin-leader(0.5);
    @include vbf-margin-trailer(0.5);
  }
}

fieldset .panel-body {
  clear: both;
}

fieldset .panel-heading a.panel-title {
  color: inherit;
  display: block;
  &:hover {
    text-decoration: none;
  }
}

.input-group-addon.glyphicon {
  vertical-align: unset;
  position: absolute;
  top: 0;
  right: 1em;
}

legend,
label {
  @include make-bold;
  font-size: 1rem;
  color: $gray-dark;
  line-height: 1em;
  &.control-label  {
    line-height: 1.25em;
  }
}

label {
  @include vbf-margin-trailer(0.25);
}

.form-actions {
  float: right;
  @include vbf-margin-leader(1);
}

/*
* Styles specific of vbf-theme-fieldset-wrapper
* Forms with this wrapper have a white background around all fields except form actions
*/
fieldset.form-fields-wrapper {
  > fieldset {
    border-top: 1px solid $gray-medium;
    padding: 1rem 0;
    margin: 0;
    @include vbf-padding-trailer(1);
    @include vbf-margin-trailer(2);
  }
}

.form-fields-wrapper {
  legend.panel-heading {
    float: left;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    @include vbf-margin-trailer(0.25);
    background: transparent;
    border: none;
  }
  .panel-title {
    font-size: rem(20);
    @include vbf-margin-trailer(1);
  }
  fieldset.panel .panel-body {
    padding: 0;
  }
  .input-group {
    width: 100%;
  }
}

.form-fields-wrapper {
  .form-type-select.form-group{

  }
}

.chosen-container{
  .chosen-choices,
  .chosen-single {
    box-shadow: none;
    border-radius: 0;
    background-image: none !important;
    span {
      color: $gray-dark;
    }
    div {
      width: $grid-gutter-width;
      b {
        @include glyphicon-after($glyphicon-triangle-bottom, $gray-dark, 11px);
        background-image: none !important;
        background: transparent !important;
        &:after {
          height: 100%;
        }
      }
    }
  }
  .chosen-drop {
    @include border-radius(0);
  }
  .chosen-results {
    margin: 0;
    padding: 0;
  }
  &.chosen-with-drop,
  &.chosen-active {
    .chosen-single div b {
      @include glyphicon-after($glyphicon-triangle-top, $gray-dark, 11px);
    }
  }
}
.chosen-container-multi {
  .chosen-choices {
    padding: 5px 10px;
    li.search-choice {
      border: none;
      box-shadow: none;
      border-radius: 0;
      background: $blue-dark;
      padding: 0.5em;
      display: flex;
      span {
         color: $white;
      }
      .search-choice-close {
        background: none !important;
        width: auto;
        height: auto;
        position: static;
        top: auto;
        right: auto;
        @include glyphicon-after($glyphicon-remove, $white, 13px);
        &:after {
          height: 100%;
          margin-left: 0.5em;
          transition: all 0.2s ease-out;
        }
        &:hover:after {
          transform: scale(1.3);
        }
      }
    }
  }
}

// -- file widget
.form-managed-file {
  width: 100%;
  .image-widget-data {
    width: 100%;

    .form-group + button.form-submit {
      @include vbf-margin-leader(-1);
    }
    button.form-submit + .form-type-select,
    .manualcrop-style-button-holder {
      clear: both;
    }
    .manualcrop-style-button-holder .btn {
      float: none;
    }
  }

  input.form-file {
    height: 3rem;
    line-height: 1;
    margin: 0;
    @include box-shadow(none);
    width: 90% !important;
    display: flex !important;
    padding: 0 5px !important;
    .webkit-browser & {
      padding: 0.8rem 5px !important;
    }
  }
  .btn {
    float: right;
  }
  .file,
  .image-preview {
    float: left;
    max-width: 60%;
    word-wrap: break-word;
  }
  .file-size {
    float: left;
    margin-left: 4%;
  }

  .form-type-textfield {
    float: left;
    clear: both;
  }
}
.managed-files a.tabledrag-handle .handle,
td.slide-cross a.tabledrag-handle .handle {
  padding: 1rem;
  background-position: 10px -8px;
}
.url-textfield,
.edit-feed-me-wrapper {
  display: none;
}

#edit-spamicide {
  display: none;
}

#edit-realname-wrapper {
  .form-item {
    margin-bottom: 0 !important;
  }

  .form-control {
    padding: 6px 12px;
    height: 35px;
  }
}