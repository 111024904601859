// Theme Customizations to Navigations //

// Lists
.list-inline {
  > li.first {
    padding-left: 0;
  }
}
.nav-tabs {
  margin-bottom: 20px;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.primary-tabs-header {
  @extend %toggle-mobile-tabs;
}
.tabs--primary {
  @extend %toggled-mobile-tabs;
  li.active > a {
    color: $gray-darker;
    background: white;
    &:hover {
      background: white;
    }
  }
}

.tabs--secondary {
  margin: 0 0 10px;
}

/**
* Missing Bootstrap 2 tab styling.
* @see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
* @see http://bootply.com/74926
*/
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: $nav-disabled-link-color;
      font-size: $font-size-small;
    }
  }
}
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

// Below.
.tabs-below {
  > .nav-tabs {
    border-top: 1px solid $nav-tabs-border-color;
    > li {
      margin-top: -1px;
      margin-bottom: 0;
      > a {
        border-radius: 0 0 $border-radius-base $border-radius-base;
        &:hover,
        &:focus {
          border-top-color: $nav-tabs-border-color;
          border-bottom-color: transparent;
        }
      }
    }
    > .active {
      > a,
      > a:hover,
      > a:focus {
        border-color: transparent $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
      }
    }
  }
}

// Left and right tabs.
.tabs-left,
.tabs-right {
  > .nav-tabs {
    padding-bottom: 20px;
    > li {
      float: none;
      &:focus {
        outline: 0;
      }
      > a {
        margin-right: 0;
        border-color: $gray-light !important;
        background: $gray-light;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  > .tab-content {
    border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
    @include box-shadow(0 1px 1px rgba(0,0,0,.05));
    border: 1px solid $nav-tabs-border-color;
    overflow: hidden;
    padding: 10px 15px;
  }
}

// Left tabs.
.tabs-left {
  > .nav-tabs {
    float: left;
    margin-right: -1px;
    > li > a {
      border-radius: 0;
      &:hover,
      &:focus {
        background: $white;
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      background: $white;
      border-right-color: transparent !important;
    }
  }
}

// Right tabs.
.tabs-right {
  > .nav-tabs {
    float: right;
    margin-left: -1px;
    > li > a {
      border-radius: 0 $border-radius-base $border-radius-base 0;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        @include box-shadow(1px 1px 1px rgba(0,0,0,.05));
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color transparent;
    }
  }
}

//
// mobile vertical tabs (left)
// --------------------------------------------------
@media (max-width: $screen-md) {
  .tabs-left {
    > .vertical-tabs-list {
      float: none;
      margin-bottom: 8px;
      padding-bottom: 0;
      width: 100%;
    }
    > .nav-tabs > li {
      float: left;
      position: relative;

      &.active > a,
      &.active > a:focus,
      &.active > a:active {
        border-right-color: $nav-tabs-border-color !important;
        color: $gray-darker;
        background: #fff;

        // Arrow + Outline
        .summary {
          position: absolute;
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;
          bottom: -16px;
          left: 50%;
          @include translate(-50%, 0);
          border-width: 8px 8px;
          border-top-color: $nav-tabs-border-color;
          z-index: 2;

          &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            bottom: -6px;
            left: 50%;
            @include translate(-50%, 0);
            border-width: 7px 7px;
            border-top-color: #fff;
            z-index: 1;
          }
        }
      }
      a {
        margin-bottom: 0;
        padding: 10px;
      }
    }
  }
}
.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-left: -$navbar-padding-horizontal;
  margin-right: -$navbar-padding-horizontal;
}

// main navigation
nav[role="navigation"] ul {
  @include make-sm-column(5);
  @media (max-width: $screen-md) {
    @include make-sm-column(5);
  }
  padding: 0;
  left: initial;

  @media (max-width: $grid-float-breakpoint-max) {
    left: 0;
  }
  @media (max-width: $screen-md) {
    padding: 0;
  }

  &.secondary {
    width: 100%;
    padding: 0;
    left: 0;
  }
}

.navbar {
  border: 0;
}

.navbar-nav {
  text-transform: initial;
  li {
    margin: 0 rem(8.5);
    @media (max-width: $screen-md) {
      margin: 0 rem(6.5);
    }

    > a {
      line-height: initial;
      padding: rem(20) 0;
    }
    &.active {
      // border-bottom: rem(3) solid $blue-lighter;
      border-bottom: initial;
      a {
        padding-bottom: rem(17);
      }
    }
  }

  &.secondary {
    li {
      font-size: rem(13);

      > a,
      > a.active {
        color: $blue;
      }
      &.active {
        color: $blue;
        border-bottom: rem(3) solid $blue;
      }
    }
  }
}


