// Theme Customizations to Views //

.view {
  .view-filters {
    @include vbf-margin-trailer(2);
  }

  .views-row {
    .field-label {
      @include make-bold;
    }
  }
}
