 section#navbar{
  height: 55px;
  margin-bottom: rem(18);
  .navbar-collapse {
    position: relative;
    padding-left: 236px;
    @media (max-width: $screen-lg) {
      padding-left: 25%;
    }
    @media (max-width: $screen-md) {
      padding-left: 17%;
    }
    > nav {
      display: flex;
      justify-content: space-between;
    }
  }
  ul.menu.nav {
    display: flex;
    align-items: center;

  }
  li {
    margin-left: $grid-gutter-width/2;
    margin-right: $grid-gutter-width/2;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      height: 55px;
      padding: 0;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      &.search-open,
      &.active,
      &:hover {
        background: none !important;
        color: $white;
        &:after {
          content: "";
          width: 100%;
          height: 4px;
          display: block;
          background: $blue-lighter;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}
#block-vbf-community-vbf-community-by-menu {
  a {
    color: rgba($white, 0.75);
  }
}

.navbar-toggle .icon-bar {
  width: 26px;
  height: 3px;
  @include border-radius(0);
}

.navbar-default .navbar-toggle {
  @include border-radius(3px);
}

.region-sidebar-first .nav,
.region-sidebar-second .nav,
#block-system-user-menu .nav {
  > li {
    // All
    a {
      @include vbf-rhythm-padding(.2);
      font-size: $font-size-h4;
      // color: $text-color;

      &:hover,
      &:active,
      &:focus {
        background: none;
      }
      &.active {
        color: $blue-dark;
      }
    }

    // First level
    > a {
      padding-left: 0;
      padding-right: 0;

      .glyphicon {
        top: 8px;
      }
    }
    // Second level
    > ul {
      padding-left: $grid-gutter-width/2;
      @include vbf-margin-trailer(.25);

      > li > a {
        display: block;
      }
    }
    &.open > a {
      @include vbf-padding-trailer(.2);
    }
  }
}

.main-container > #block-system-user-menu {
  @include vbf-margin-trailer(1);
}

//////////////////
// FOOTER MENU //
////////////////
#block-vbf-community-vbf-community-footer-menu ul.menu {
  overflow: hidden;
  padding: 0;
  margin: 0;
  color: $blue-dark;
  font-weight: 400;
  > li {
    float: left;

    &:before {
      content: '|';
      margin: 0 .5em;
    }
    &:first-child:before {
      content: '';
      margin: 0;
    }
    a {
      color: #225486;
    }
  }
}
