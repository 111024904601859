// Everything that implements the sidebar  //
$sidebar-width: 320px;
#page-wrapper {
  padding-left: 0;
  @include transition(all 0.5s ease);
  @media (max-width: $grid-float-breakpoint-max) {
    &.sidebar {
      #sidebar-wrapper {
        width: $sidebar-width;
        // max-width: $sidebar-width;
        // width: 100%;        
        @include box-shadow(4px 0 4px 0px rgba(0,0,0,.5));
      }
      #page-content-wrapper {
        position: absolute;
      }
    }
  }
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  right: $sidebar-width;
  width: 0;
  height: 100%;
  margin-right: -$sidebar-width;
  overflow-y: auto;
  overflow-x: hidden;
  background: $blue_darker;
  @include transition(all 0.3s ease);

  #sidebar-content {
    position: absolute;
    left: 0%;
    @include transition(all 0.3s ease);
  }
}

#sidebar-overlay {
  @include opacity(0);
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;

  @media (max-width: $grid-float-breakpoint-max) {
    display: block;
  }
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#sidebar-wrapper {
  #sidebar-header {
    @include vbf-rhythm-padding(.815);
    @include padding-gutter;
    position: relative;
    width: 100%;
    min-width: $sidebar-width;
    border-bottom: 1px solid mix($blue_darker, $blue_dark, 75%);
    color: white;
    text-transform: uppercase;
    @include make-bold;
  }
  #sidebar-toggle-close {
    position: absolute;
    right: 0;
    top: 9px;

    .icon-bar {
      background: white;
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: -3px;
      margin-top: -2px;

      @include rotate(45deg);
      & + .icon-bar {
        @include rotate(-45deg);
      }
    }
  }
  #sidebar-content {
    width: 100%;
    color: white;

    > ul {
      margin: 0;

      .caret {
        display: none;
      }
    }
    a {
      color: white;
    }
    .block-vbf-community-vbf-community-user {
      float: none !important;
      > a {
        display: block;
      }
    }
    .navbar-nav {
      width: 100%;
      margin-left: 0;

      li {
        margin: 0;
      }
    }
    .nav > li,
    .dropdown-menu > li {
      position: static;
    }
    .nav > li > a,
    .dropdown-toggle {
      @include transition(background-color 0.5s ease);
    }
    .nav > li > a.active,
    .nav > li > a:hover,
    .nav > li > a:focus,
    .dropdown-toggle.active,
    .dropdown-toggle:hover,
    .dropdown-toggle:focus {
      background: initial;
    }
    .nav .open > a {
      background: transparent;
    }
    .navbar-nav li > a,
    .block-vbf-community-vbf-community-user a,
    .block-locale a {
      line-height: initial;
      padding: rem(20) 0 rem(18) 0;
      text-transform: uppercase;
      padding-left: $grid-gutter-width / 2;
      width: 100%;
      min-width: 250px;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .block-vbf-community-vbf-community-user {
      a {
        @include vbf-rhythm-padding(.5);
      }
      &.login-block a {
        padding: rem(20) 0 rem(18) $grid-gutter-width / 2;
      }
    }
    .navbar-nav li.active,
    .block-vbf-community-vbf-community-user li.active,
    .block-locale li.active {
      //border-bottom: rem(1) solid $blue-lighter;
      //border-bottom-width: 0;

      a {

      }
    }
    > ul.menu {
      //background: mix($blue_darker, $blue_dark, 75%);
    }
    .block-vbf-community-vbf-community-user,
    > ul.menu li,
    .block-locale {
      border-bottom: rem(1) solid mix($blue_darker, $blue_dark, 75%);

      ul.dropdown-menu li {
        border-bottom: rem(1) solid mix($blue_darker, $blue_dark, 75%);
      }
    }

    .block-locale {
      //border-top: rem(1) solid mix($blue_darker, $blue_dark, 75%);
    }
    .dropdown-menu {
      @include box-shadow(none);
      border: none;
      position: absolute;
      width: 100%;
      top: 0;
      left: 100%;

      > li {
        @include transition(background-color 0.5s ease);
      }
      > li.active,
      > li:hover,
      > li:focus {
        background: $blue;
      }
    }
    .dropdown-toggle {
      @include glyphicon-after($glyphicon-menu-right);
      position: relative;
      display: block;

      &:after {
        position: absolute;
        right: 9px;
        top: 50%;
        @include translate(0, -50%);
      }
    }
    .dropdown-arrow {
      display: none;
    }
    .dropdown-header {
      @include transition(background-color 0.5s ease);
      @include vbf-rhythm-padding;
      @include padding-gutter();
      position: relative;
      color: white;
      text-transform: uppercase;
      border-bottom: 1px solid mix($blue_darker, $blue_dark, 75%);
      cursor: pointer;

      &:hover {
        background: $blue;
      }
      .back {
        @include glyphicon($glyphicon-menu-left);
        @include translate(0, -50%);
        position:absolute;
        right: 16px;
        top: 50%;

        &:before {
          position: absolute;
          top: 3px;
          left: -20px;
        }
      }
    }
    .block-vbf-community-vbf-community-user {
      .my-messages-combined {
        @include transition(background-color 0.5s ease);
      }
      &:hover {
        .my-messages-combined {
          background: $blue_darker;
        }
      }
      .dropdown-header {
        @include vbf-rhythm-padding(.5);
      }
      .dropdown-menu {
        > div {
          @include padding-gutter;

          &:first-child + div {
            @include vbf-rhythm-padding(.5);
          }
          &:nth-last-child(2) {
            border-top: 2px solid $blue;
            @include vbf-padding-leader(.5);
          }
          &:last-child {
            @include vbf-padding-trailer(.5);
          }
        }
        li {

          &.active {
            background: mix($blue_darker, $blue_dark, 50%);
            background: $blue;
          }
          a {
            @include vbf-rhythm-padding;
            position: relative;

            &.active {
              border-bottom-width: 0;
            }
            &:hover {
              .my-messages-count,
              .my-notifications-count {
                background: $blue_darker;
                @include transition(background-color 0.5s ease);
              }
            }
            .my-messages-count,
            .my-notifications-count {
              @include transition(background-color 0.5s ease);
              position: absolute;
              right: $grid-gutter-width/2;
              top: 50%;
              @include translate(0, -50%);
              font-size: rem(11);
            }
          }
        }
      }
      .dropdown-toggle {
      }
      .dropdown-arrow {
        top: rem(100  - $tooltip-arrow-width);
      }
      input, button {
        min-width: 220px;
      }
      input {
        @include placeholder-color($blue-dark);
        border-color: white;
      }
      button {
        color: white;
        background: $blue;
        border-color: $blue;
      }
      .my-messages-combined {
        background: $blue;
        left: rem(50);
        top: rem(10);
        right: auto;
        font-size: rem(11);

        &.active {
          background: $blue_darker;
        }
      }
      .field-profile-image {
        width: 40px;
      }
      .realname {
        @include translate(0, -50%);
        display: block;
        position: absolute;
        left: 65px;
        top: 50%;
        //text-transform: none;
        width: 110px;
        white-space: normal;
        font-weight: normal;

      }
      .dropdown-header {
        .realname {
          display: none;
        }
      }
      .glyphicon-menu-down {
        display: none;
      }
      &.login-block {
        .dropdown-header {
          @include vbf-rhythm-padding;
        }
        .dropdown-menu a {
          padding: 0;
          color: $blue;
          text-transform: none;
          text-decoration: underline;
        }
      }
    }
  }
}
