.table-bordered {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-right: 0;
        border-left: 0;
        vertical-align: middle;
        padding: $grid-gutter-width / 2;
        @include vbf-rhythm-padding(0.5);
      }
    }
  }
}

.table {
  > thead,
  > tbody,
  > tfoot {
    > tr > th {
      font-size: $font-size-h4;
      font-weight: normal;
      @include vbf-rhythm-padding(.5);

      .glyphicon {
        margin-left: 8px;
      }
    }
    > tr > td {
      background: white;
      line-height: 1.25;
    }

    // Get rid of active background, hover background.
    > tr,
    > tr.active,
    > tr:hover {
      > th,
      > th.active,
      > th:hover {
        background: transparent;
      }
      > td,
      > td.active,
      > tr:hover {
        background: white;
      }
    }
  }
  > tbody > tr > td a {
    text-transform: uppercase;
  }
}
