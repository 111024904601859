// Theme Customizations to HTML Elements //

img {
  @include img-responsive;
}
img.manualcrop-image {
  display: inline;
}

.popover-content {
  ul {
    padding: 0 1rem;
  }
}


