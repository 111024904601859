// Print styles //

@media print {
  * {
    @include box-sizing(unset);
    &:before,
    &:after {
      @include box-sizing(unset);
    }
  }

  html, body {
    background: transparent;
    color: black !important; // ensure boxed text is also readable
    padding: 0 !important;
  }

  .contextual-links-wrapper,
  .tabs--primary {
    display: none !important;
  }

  .background-icon {
    display: none !important;
  }

  // -- regions
  .region-wrapper {
    clear: both !important;
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  // -- Swipers
  .swiper-wrapper {
    width: 100% !important;
    display: block !important;
  }
  .swiper-container {
    overflow: visible !important;
    width: 100% !important;
  }
  .swiper-pagination {
    display: none;
  }

  .block-views {
    form {
      display: none;
    }
  }

  ul {
    &.pager {
      display: none;
    }
  }

  // -- Forms
  form {
    width: 100%;
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    textarea,
    select {
      padding: 0 !important;
      border: 1px solid gray !important;
    }
    input:required,
    textarea:required,
    select:required {
      @include box-shadow(none !important);
      outline: none !important;
    }
    .grippie {
      display: none !important;
    }
  }
}
