// Theme Customizations for admin components //
// mainly disables them, when they're unhandy (small screens) //

@media (max-width: $screen-xs-min - 1) {
  html body.admin-menu {
    margin-top: 0 !important;
  }
  #admin-menu-wrapper {
    display: none;
  }
}
@media (max-width: $screen-sm-min - 1) {
  #sidebar-wrapper .contextual-links-region {
    position: static;
  }
}
